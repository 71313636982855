// External Imports
import React, { useState } from 'react';
import PropTypes from 'prop-types';

import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Dropdown from 'react-bootstrap/Dropdown';
import Form from 'react-bootstrap/Form';
import NavItem from 'react-bootstrap/NavItem';
import NavLink from 'react-bootstrap/NavLink';
import Row from 'react-bootstrap/Row';

import * as formik from 'formik';
import * as yup from 'yup';

// Hooks
import useToken from '../../utils/useToken';

// Create View
export default function CreateRatePlan({ ratePlansChanged, changeRatePlans }) {
  const { token, isValidToken } = useToken();

  // Local Data
  const [page, setPage] = useState('');
  const [error, setError] = useState({ message: '', severity: 'primary' });

  // Event Handlers
  const handleSelect = (eventKey) => setPage(eventKey);

  const handleForm = async (values, { resetForm }) => {
    if (!isValidToken()) {
      window.location.reload(false);
    }

    let ratePlanObj = {};
    let bandwidth =
      values.downlink_mir == values.uplink_mir
        ? values.downlink_mir
        : values.uplink_mir + '_' + values.downlink_mir;

    switch (page) {
      case 'Dedicated':
        ratePlanObj = {
          plan_name: 'Dedicated_' + bandwidth + '_Mbps',
          plan_description:
            'Dedicated ' + values.uplink_mir + ' Mbps up, ' + values.downlink_mir + ' Mbps down',
          downlink_mir: values.downlink_mir * 1024,
          uplink_mir: values.uplink_mir * 1024,
        };
        break;
      case 'Fibre_Burst':
        ratePlanObj = {
          plan_name: 'Fibre_Burst_' + bandwidth + '_Mbps',
          plan_description:
            'Fibre Burst: ' +
            values.uplink_mir +
            ' Mbps up, ' +
            values.downlink_mir +
            ' Mbps down MIR, 2:1 shared',
          downlink_mir: values.downlink_mir * 1024,
          uplink_mir: values.uplink_mir * 1024,
          downlink_cir: (values.downlink_mir * 1024) / 4,
          uplink_cir: (values.uplink_mir * 1024) / 4,
        };
        break;
      case 'Fibre_Access':
        ratePlanObj = {
          plan_name: 'Fibre_Access_' + bandwidth + '_Mbps',
          plan_description:
            'Fibre Access: ' +
            values.uplink_mir +
            ' Mbps up, ' +
            values.downlink_mir +
            ' Mbps down MIR, 5:1 shared',
          downlink_mir: values.downlink_mir * 1024,
          uplink_mir: values.uplink_mir * 1024,
          downlink_cir: (values.downlink_mir * 1024) / 10,
          uplink_cir: (values.uplink_mir * 1024) / 10,
        };
        break;
      case 'Fibre_Home':
        ratePlanObj = {
          plan_name: 'Fibre_Home_' + bandwidth + '_Mbps',
          plan_description:
            'Fibre Home: ' +
            values.uplink_mir +
            ' Mbps up, ' +
            values.downlink_mir +
            ' Mbps down MIR, 5:1 shared',
          downlink_mir: values.downlink_mir * 1024,
          uplink_mir: values.uplink_mir * 1024,
          downlink_cir: (values.downlink_mir * 1024) / 10,
          uplink_cir: (values.uplink_mir * 1024) / 10,
        };
        break;
      case 'Pooled_Bandwidth':
        ratePlanObj = {
          plan_name: 'Pooled_' + bandwidth + '_Mbps' + '_' + values.contention,
          plan_description:
            'Pooled Bandwidth: ' +
            values.uplink_mir +
            ' Mbps up, ' +
            values.downlink_mir +
            ' Mbps down MIR, ' +
            values.contention +
            ':1 shared',
          downlink_mir: values.downlink_mir * 1024,
          uplink_mir: values.uplink_mir * 1024,
          downlink_cir: (values.downlink_mir * 1024) / values.contention,
          uplink_cir: (values.uplink_mir * 1024) / values.contention,
        };
        break;
      default:
        setError('Can not create rate plan to type ' + page);
        return;
    }

    await fetch(process.env.REACT_APP_BACKEND_URL + '/v1/rate_plans', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
      },
      body: JSON.stringify(ratePlanObj),
    })
      .then((response) => {
        if (response.ok) {
          setError({ message: 'Rate Plan creation has been requested.', severity: 'success' });
          resetForm();
          changeRatePlans(true);
        } else {
          return response.json().then((text) => {
            throw new Error(text['detail']);
          });
        }
      })
      .catch((error) => {
        setError({ message: 'Could not create rate plan! ' + error, severity: 'danger' });
      });
  };

  // Create View
  return (
    <Card>
      <Card.Header>
        <Dropdown as={NavItem} onSelect={handleSelect}>
          <Dropdown.Toggle as={NavLink}>Create Rate Plan</Dropdown.Toggle>
          <Dropdown.Menu>
            <Dropdown.Item eventKey='Dedicated'>Dedicated</Dropdown.Item>
            <Dropdown.Item eventKey='Fibre_Burst'>Fibre Burst</Dropdown.Item>
            <Dropdown.Item eventKey='Fibre_Access'>Fibre Access</Dropdown.Item>
            <Dropdown.Item eventKey='Fibre_Home'>Fibre Home</Dropdown.Item>
            <Dropdown.Item eventKey='Pooled_Bandwidth'>Pooled Bandwidth</Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </Card.Header>
      <Card.Body>
        {error.message != '' && (
          <Alert variant={error.severity}>
            <span>{error.message}</span>
          </Alert>
        )}
        {page === '' && <p>Select a rate plan type above!</p>}
        {page === 'Dedicated' && (
          <>
            <h3>Dedicated Bandwidth</h3>
            <CreateForm handleForm={handleForm} />
          </>
        )}
        {page === 'Fibre_Burst' && (
          <>
            <h3>Fibre Burst (2:1 contention)</h3>
            <CreateForm handleForm={handleForm} />
          </>
        )}
        {page === 'Fibre_Access' && (
          <>
            <h3>Fibre Access (5:1 contention)</h3>
            <CreateForm handleForm={handleForm} />
          </>
        )}
        {page === 'Fibre_Home' && (
          <>
            <h3>Fibre Home (5:1 contention)</h3>
            <CreateForm handleForm={handleForm} />
          </>
        )}
        {page === 'Pooled_Bandwidth' && (
          <>
            <h3>Pooled Bandwith (with CIR)</h3>
            <CreateForm handleForm={handleForm} selectContention={true} />
          </>
        )}
      </Card.Body>
    </Card>
  );
}

function CreateForm({ handleForm, selectContention = false }) {
  const { Formik } = formik;

  yup.addMethod(yup.number, 'fibreBandwidth', function (pooled, message) {
    return this.test('Fibre Bandwidth', message, function (value) {
      if (value == null) return true; //Not enforcing required and > 0

      if (value % 250 == 0) return true;
      if (value <= 1000 && value % 100 == 0) return true;
      if (value <= 500 && value % 50 == 0) return true;
      if (value <= 250 && value % 25 == 0) return true;
      if (value <= 150 && value % 10 == 0) return true;
      if (value <= 50 && value % 5 == 0) return true;
      if (value <= 20 && value % 2 == 0) return true;
      if (value <= 10 && value % 1 == 0) return true;

      if (pooled) {
        if (value <= 100 && value % 2 == 0) return true;
        if (value % 5 == 0) return true;
      }

      return false;
    });
  });

  const schema = yup.object().shape({
    uplink_mir: yup
      .number()
      .min(1, 'MIR must be at least 1')
      .fibreBandwidth(
        selectContention,
        'Please follow business guidelines on available bandwidth options',
      )
      .required(),
    downlink_mir: yup
      .number()
      .min(1, 'MIR must be at least 1')
      .fibreBandwidth(
        selectContention,
        'Please follow business guidelines on available bandwidth options',
      )
      .required(),
    contention: yup.number().min(2).max(16),
  });

  const validateContention = (values, props) => {
    const errors = {};

    if (values.contention != '') {
      if (values.downlink_mir != '') {
        if (values.downlink_mir % values.contention != 0) {
          errors.downlink_mir = 'Downlink MIR is not divisible by contention!';
          errors.contention = 'Downlink MIR is not divisible by contention!';
        }
      }

      if (values.uplink_mir != '') {
        if (values.uplink_mir % values.contention != 0) {
          errors.uplink_mir = 'Uplink MIR is not divisible by contention!';
          errors.contention = 'Uplink MIR is not divisible by contention!';
        }
      }

      if (values.downlink_mir % values.uplink_mir != 0) {
        errors.downlink_mir = 'Downlink MIR must be a (integer) multiple of Uplink MIR!';
        errors.uplink_mir = 'Downlink MIR must be a (integer) multiple of Uplink MIR!';
      }
    }

    return errors;
  };

  return (
    <Formik
      validationSchema={schema}
      validate={validateContention}
      onSubmit={async (values, { resetForm }) => {
        await handleForm(values, { resetForm });
      }}
      initialValues={{
        uplink_mir: '',
        downlink_mir: '',
        contention: selectContention ? 4 : '',
      }}
    >
      {({ handleSubmit, handleChange, values, touched, errors }) => (
        <Form noValidate onSubmit={handleSubmit}>
          <Form.Group as={Row} className='mb-3' controlId='uplink_mir'>
            <Form.Label column sm={2}>
              Uplink (Mbps)
            </Form.Label>
            <Col sm={2}>
              <Form.Control
                type='text'
                placeholder='Uplink MIR (Mbps)'
                name='uplink_mir'
                value={values.uplink_mir}
                onChange={handleChange}
                isInvalid={!!errors.uplink_mir}
              />
              <Form.Control.Feedback type='invalid'>{errors.uplink_mir}</Form.Control.Feedback>
            </Col>
          </Form.Group>

          <Form.Group as={Row} className='mb-3' controlId='downlink_mir'>
            <Form.Label column sm={2}>
              Downlink (Mbps)
            </Form.Label>
            <Col sm={2}>
              <Form.Control
                type='text'
                placeholder='Downlink MIR (Mbps)'
                name='downlink_mir'
                value={values.downlink_mir}
                onChange={handleChange}
                isInvalid={!!errors.downlink_mir}
              />
              <Form.Control.Feedback type='invalid'>{errors.downlink_mir}</Form.Control.Feedback>
            </Col>
          </Form.Group>

          {selectContention ? (
            <Form.Group as={Row} className='mb-3' controlId='contention'>
              <Form.Label column sm={2}>
                Contention
              </Form.Label>
              <Col sm={2}>
                <Form.Select
                  aria-label='Select the contention'
                  name='contention'
                  value={values.contention}
                  isInvalid={!!errors.contention}
                  onChange={handleChange}
                >
                  <option value='2'>2:1</option>
                  <option value='3'>3:1</option>
                  <option value='4'>4:1</option>
                  <option value='5'>5:1</option>
                  <option value='8'>8:1</option>
                  <option value='10'>10:1</option>
                  <option value='16'>16:1</option>
                </Form.Select>
                <Form.Control.Feedback type='invalid'>{errors.contention}</Form.Control.Feedback>
              </Col>
            </Form.Group>
          ) : (
            <input type='hidden' name='contention' value='' />
          )}

          <Button variant='success' type='submit'>
            Create
          </Button>
        </Form>
      )}
    </Formik>
  );
}

CreateRatePlan.propTypes = {
  ratePlansChanged: PropTypes.bool,
  changeRatePlans: PropTypes.func,
};

CreateForm.propTypes = {
  handleForm: PropTypes.func,
  selectContention: PropTypes.bool,
};
